import { Box } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import {
    useGetRolesQuery,
    useGetUserListQuery,
    useRemoveUserMutation,
    useUpdateUserMutation,
} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { PasteToClipboard, selectMapFn } from "../../common/Util";
import { InputType } from "../../common/FormRenderer";
import CreateView from "../custom/CreateView";
import NotificationPopup from "../../common/NotificationPopup";
import { DataGridLocale } from "../../common/DataGridLocale";
import {
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport,
    GridValueFormatterParams,
    GridValueGetterParams,
    DataGridPro, GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import ConfirmButton from "../../components/ConfirmButton";
import UpdateUser from "./UpdateUser";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
}
export default () => {
    const { loading, data, error } = useGetUserListQuery();
    const [updateUser] = useUpdateUserMutation();
    const [removeUser] = useRemoveUserMutation();
    const rolesQuery = useGetRolesQuery();
    const onRemove = async (id) => {
        try {
            await updateUser({
                variables: {
                    id: id,
                    user: {
                        disabled: true,
                    },
                },
                refetchQueries: ["getUserList"],
            });
            NotificationPopup.success(`刪除完成`);
        } catch (e) {
            NotificationPopup.error(`刪除發生問題: ${e.message}`);
            console.error(e);
        }
    };
    const columns: GridColDef[] = [
        { field: "name", headerName: "姓名", width: 150 },
        { field: "email", headerName: "Email", width: 300 },
        {
            field: "roles",
            headerName: "角色",
            width: 400,
            valueGetter: (params: GridValueGetterParams) => {
                return (params?.row.roles as Array<any>)
                    .map(({ name }) => name)
                    .join(", ");
            },
        },
        {
            field: "test",
            headerName: "動作",
            width: 200,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <>
                        <UpdateUser user={params.row}/>
                        &ensp;
                        <ConfirmButton
                            onConfirm={async () => {
                                await onRemove(params.row.id);
                            }}
                            queryEntity={"user"}
                            queryAction={"刪除使用者"}
                            entityId={params.row.id}
                            queryMessage={`名稱: ${params.row.name}`}
                        >
                            刪除
                        </ConfirmButton>
                    </>
                );
            },
        },
    ];
    return (
        <DefaultQueryHandler
            error={error}
            loading={loading || rolesQuery.loading}
        >
            <Box width={"100%"} style={{ marginTop: "30px", height: "100vh" }}>
                <DataGridPro
                    pagination
                    pageSize={10}
                    columns={columns}
                    rows={data?.users!}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
