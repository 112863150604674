import React, {useCallback, useState} from "react";
import {InputType} from "../../common/FormRenderer";
import {useCreateOrderItemMutation, useGetOrderDataQuery, useGetUserListQuery,} from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import NotificationPopup from "../../common/NotificationPopup";
import CreateView from "../custom/CreateView";
import {selectMapFn} from "../../common/Util";
import {BelongingList, OrderItemTypeList} from "../../common/Constant";
import {FieldTagList, ProductTagList} from "../../common/IndustryConstant";


export default ({ order }) => {
    // const [open, setOpen] = useState(false);
    const { loading, data, error } = useGetOrderDataQuery();
    const userListQuery = useGetUserListQuery();
    const [createOrderItem] = useCreateOrderItemMutation({
        refetchQueries: ["getOrder"],
    });
    const [suppliers, setSuppliers] = useState<any>([]);
    const onSubmit = useCallback(
        async (orderItem, { resetForm }, setOpen) => {
            try {
                const newOrderItem = await createOrderItem({
                    variables: {
                        orderItem: {...orderItem, orderId: order.id},
                    },
                });
                NotificationPopup.success(`建立完成`);
                return newOrderItem.data?.createOrderItem;
            } catch (e) {
                NotificationPopup.error(
                    `建立委刊細項發生問題: ${e.message}`
                );
                console.error(e);
            } finally {
                resetForm();
                setOpen(false);
            }
        },
        [createOrderItem]
    );

    return (
        <DefaultQueryHandler loading={loading&&userListQuery.loading} error={error}>
            <CreateView
                title={"建立委刊細項"}
                columns={[
                    {
                        name: "productId",
                        label: "商品",
                        type: InputType.autoComplete,
                        options: data?.products.map(selectMapFn),
                        onChange: (selectId) => {
                            data?.products.map((product) => {
                                if (product.id == selectId) {
                                    setSuppliers(product.suppliers);
                                }
                            })
                        },
                        required: true,
                    },
                    { name: "type", label: "操作方式", type: InputType.select, options: OrderItemTypeList },
                    { name: "totalAmount", label: "購買金額", type: InputType.number },
                    { name: "serviceFee", label: "帳戶執行費", type: InputType.number },
                    { name: "budget", label: "成本預算", type: InputType.number },
                    {
                        name: "assigneeId",
                        label: "專案負責人",
                        type: InputType.autoComplete,
                        options: userListQuery.data ? userListQuery.data?.users.map(selectMapFn) : [],
                        required: true,
                    },
                    {
                        name: "supporterId",
                        label: "協助執行人",
                        type: InputType.autoComplete,
                        options: userListQuery.data ? userListQuery.data?.users.map(selectMapFn) : [],
                    },
                    {
                        name: "startDate",
                        label: "開始時間",
                        type: InputType.date,
                    },
                    {
                        name: "endDate",
                        label: "結束時間",
                        type: InputType.date,
                    },
                    { name: "description", label: "備註", type: InputType.textarea },
                    {
                        name: "productTag",
                        label: "產品標籤",
                        type: InputType.autoComplete,
                        options: ProductTagList.map((product)=>({value: product.id,label: `${product.id} ${product.name}`})),
                        value: order?.advertiser ? order.advertiser.productTag : order.customer.productTag,
                        required: true,
                    },
                    {
                        name: "fieldTag",
                        label: "場域標籤",
                        type: InputType.select,
                        options: FieldTagList.map((field)=>({value: field.id,label: `${field.id} ${field.name}`
                        })),
                        value: order?.advertiser ? order.advertiser.fieldTag : order.customer.fieldTag,
                    },
                    {
                        name: "belonging",
                        label: "業績歸屬",
                        type: InputType.select,
                        options: BelongingList,
                        value: `${process.env.REACT_APP_TENANT_NAME}`,
                    },
                ]}
                onSubmit={onSubmit}
                queryEntity={"orderItem"}
            />
        </DefaultQueryHandler>
    );
};
