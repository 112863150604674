import { DocumentNode, useLazyQuery } from "@apollo/client";
import React, {useCallback, useEffect, useState} from "react";
import {
    Comparator,
    FilteringInputType,
    OrderingInputType,
} from "../generated/graphql";
import { get, filter, map, isEqual,debounce } from "lodash";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { DataGridLocale } from "../common/DataGridLocale";
import {createSearchParams, useSearchParams} from "react-router-dom";
import {useNavigate} from "react-router";
import { PasteToClipboard } from "../common/Util";
import {
    Badge,
    Button,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {
    GridColDef,
    GridFilterItem, GridFilterModel,
    GridSortItem,
    GridSortModel,
    GridValueFormatterParams,
    DataGridPro,
    GridToolbarContainer,
    GridToolbarExport,
    GridColumnResizeParams, GridEnrichedColDef, GridValueGetterParams, GridCellParams, GridColumnOrderChangeParams,
} from "@mui/x-data-grid-pro";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport csvOptions={{utf8WithBom:true}}/>
        </GridToolbarContainer>
    );
}


const getComparatorEquivalent = (operatorValue: string | undefined) => {
    switch (operatorValue) {
        case "equals":
            return Comparator.Equals;
        case "contains":
            return Comparator.Like;
        case "startsWith":
            return Comparator.Starts;
        case "endsWith":
            return Comparator.Ends;
        case "after":
        case ">":
            return Comparator.Gt;
        case "onOrAfter":
        case ">=":
            return Comparator.Gte;
        case "before":
        case "<":
            return Comparator.Lt;
        case "onOrBefore":
        case "<=":
            return Comparator.Lte;
        case "isEmpty":
            return Comparator.Isnull;
        case "isNotEmpty":
            return Comparator.Notnull;
        default:
            console.warn(`No comparator equivalent defaulting to "Like". 
        Filtering this way probably wont work as expected `);
            return Comparator.Like;
    }
};

const convertFilterModel = (
    filterModel: GridFilterModel | undefined,
    defaultFilterItems: GridFilterItem[],
): FilteringInputType[] => {
    let concatItems = filterModel && filterModel.items && filterModel.items.length > 0 ? filterModel.items.concat(defaultFilterItems) : defaultFilterItems
    if (concatItems) {
        return filter(
            concatItems.map<FilteringInputType | null>(
                ({
                    columnField,
                    value,
                    operatorValue,
                }: GridFilterItem): FilteringInputType | null => {
                    const operatorEnum = getComparatorEquivalent(operatorValue);
                    if (value) {
                        return {
                            key: columnField,
                            value,
                            comparator: operatorEnum,
                        } as FilteringInputType;
                    }
                    else if (operatorEnum == Comparator.Isnull || operatorEnum == Comparator.Notnull)
                        return {
                            key: columnField,
                            value: '0',
                            comparator: operatorEnum,
                        } as FilteringInputType;
                    return null as any;
                }
            ),
            (s) => s !== null
        ) as FilteringInputType[];
    }

    return [];
};

const convertSortModel = (
    sortModel: GridSortModel | undefined
): OrderingInputType => {
    if (sortModel && sortModel.length > 0) {
        const { field, sort } = sortModel[0];
        return { id: field, desc: sort === "desc" };
    } else {
        return {};
    }
};
const defaultValueGetter = (params: GridValueGetterParams) => {
    return get((params as GridCellParams).row, params.field);
};

export interface FilterButton {
    label: string;
    filter: GridFilterModel;
    count?: number;
    // sortModel?:SortModel;
}

const mapFilterItem = ({ value, operatorValue, columnField }: GridFilterItem) => {
    return { value, operatorValue, columnField };
};

const filterModelIsEqual = (a: GridFilterModel, b: GridFilterModel) => {
    return isEqual(map(a?.items, mapFilterItem), map(b?.items, mapFilterItem));
};

interface PaginatedDatagridParams {
    id: string;
    query: DocumentNode;
    columns: GridEnrichedColDef[];
    ordering?: GridSortItem;
    filterButtons?: FilterButton[];
    queryParams?:boolean;
    defaultFilterItems?: GridFilterItem[];
    defaultPageSize?: number;
    refetchState?: any,
}

const StyledButton = withStyles({
    root: {
        "&$selected": {
            backgroundColor: "#3f51b5",
            color: "#FFF",
            "&:hover": {
                backgroundColor: "#3f51b5",
                color: "#FFF",
            },
        },
    },
    selected: {},
})(ToggleButton);

export default ({
    id,
    query,
    columns: initialColumns,
    ordering,
    filterButtons,
    queryParams,
    defaultFilterItems = [],
    defaultPageSize = 30,
    refetchState,
}: PaginatedDatagridParams) => {
    const columnsOrderKey = `columnsOrder_${id}`;
    const columnWidthKeyPrefix = `columnWidth_${id}_`;

    const [searchParams,setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [fetch, { loading, error, data }] = useLazyQuery(query, {
        fetchPolicy: "network-only",
    });
    const debouncedFetch:(data:any)=>void = useCallback(debounce((data)=>
    {
        fetch(data);
    },200),[fetch]);
    const [page, setPage] = useState(searchParams.get("page") && queryParams ?
        parseInt(searchParams.get("page") as string):0);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [filterModel, setFilterModel] = useState<
        GridFilterModel | undefined
    >(searchParams.get('filterField') && queryParams? {
        items:[{
            columnField:searchParams.get('filterField') as string,
            value:searchParams.get("filterValue") as string,
            operatorValue:searchParams.get("filterOperator") as string
        }],
    }:undefined);
    const [rowCount, setRowCount] = useState(0);
    const [rows, setRows] = useState([]);
    const [sortModel, setSortModel] = useState<GridSortModel | undefined>(
        ordering ? [ordering] : []
    );
    const [columns, setColumns] = useState(() => {
        // 嘗試從 localStorage 中讀取欄位順序
        const storedOrder = localStorage.getItem(columnsOrderKey);
        if (storedOrder) {
            const order = JSON.parse(storedOrder);
            const orderedColumns = order.map(field => initialColumns.find(c => c.field === field)).filter(Boolean);
            if (orderedColumns.length === initialColumns.length) {
                // 如果成功恢復排序，則使用該排序
                return orderedColumns;
            }
        }
        // 默認或恢復失敗時使用初始列
        console.log('read init');
        return initialColumns;
    });
    const handleColumnOrderChange = useCallback((params: GridColumnOrderChangeParams) => {
        const newColumns = [...columns];
        const movedColumn = newColumns.splice(params.oldIndex, 1)[0];
        newColumns.splice(params.targetIndex, 0, movedColumn);

        setColumns(newColumns);

        // Save the new order to localStorage
        const newOrder = newColumns.map(column => column.field);
        localStorage.setItem(columnsOrderKey, JSON.stringify(newOrder));
    }, [columns]);

    useEffect(() => {
        debouncedFetch({
            variables: {
                pagination: { pageIndex: page, pageSize },
                filtering: convertFilterModel(filterModel, defaultFilterItems),
                ordering: convertSortModel(sortModel),
            },
        });
    }, [page, pageSize, filterModel, sortModel]);

    useEffect(() => {
        if (refetchState) {
            debouncedFetch({
                variables: {
                    pagination: { pageIndex: page, pageSize },
                    filtering: convertFilterModel(filterModel, defaultFilterItems),
                    ordering: convertSortModel(sortModel),
                },
            });
        }
    }, [refetchState]);
    useEffect(() => {
        if (data) {
            setRowCount(data.result.total);
            setRows(data.result.list);
        }
    }, [data]);
    useEffect(()=>
    {
        if(queryParams)
        {
            let params = {};
            if(filterModel?.items[0]?.columnField && filterModel?.items[0]?.value)
            {
                const filter = filterModel.items[0];
                params = {
                    filterField:filter?.columnField as string,
                    filterValue:filter.value as string,
                    filterOperator:filter.operatorValue as string
                };
            }
            navigate({
                search:'?' + createSearchParams({page:page.toString(), ...params}).toString(),
            });
        }
    },[page,setSearchParams,filterModel]);
    if (error && !data) {
        console.error(error);
        return (
            <div>Failed to fetch data to display datagrid. {error.message}</div>
        );
    }
    const [selectedIndex, setIndex] = useState<string | null>();
    const pageCount = Math.ceil(rowCount/pageSize);
    return (
        <div style={{ display:"flex", flexDirection: "column", height: "90vh" }}>
            {/* <pre>
            page: {page} <br/>
            pagesize: {pageSize} <br/>
            filtermodel: {JSON.stringify(filterModel)} <br/>
            rowcount: {rowCount} <br/>
            sortModel: {JSON.stringify(sortModel)} <br/>
        </pre> */}
            <ToggleButtonGroup
                exclusive
                value={selectedIndex}
                onChange={(
                    event: React.MouseEvent<HTMLElement>,
                    index: string
                ) => {
                    setIndex(index);
                    if (index != null) {
                        let filterButton = filterButtons![index];
                        setFilterModel(filterButton.filter);
                        if (sortModel) {
                            setSortModel(sortModel);
                        }
                    } else {
                        setFilterModel(undefined);
                    }
                }}
            >
                {filterButtons?.map(({ filter, label, count }, index) => {
                    return (

                        <StyledButton
                            style={
                                filterModelIsEqual(filter, filterModel!)
                                    ? {
                                          backgroundColor: "#3f51b5",
                                          color: "#FFF",
                                      }
                                    : {}
                            }
                            value={index}
                        >
                            <Badge color="error" badgeContent={count}>
                                {label}
                            </Badge>
                        </StyledButton>
                    );
                })}
            </ToggleButtonGroup>
            <DataGridPro
                // autoHeight={true}
                rows={rows}
                columns={columns.map((c) => ({
                    width: parseInt(window.localStorage.getItem(`${columnWidthKeyPrefix}${c.field}`,) ?? '200') ,
                    valueGetter: defaultValueGetter,
                    ...c,
                }))}
                pagination
                rowCount={rowCount}
                pageSize={pageSize}
                page={page}
                autoPageSize={false}
                hideFooter={true}
                hideFooterPagination={true}
                paginationMode="server"
                onPageSizeChange={(pageSize) => {
                    setPageSize(pageSize);
                }}
                onPageChange={(page) => {
                    setPage(page);
                }}
                onFilterModelChange={(filterModel) => {
                    setFilterModel(filterModel);
                }}
                sortingMode={"server"}
                sortModel={sortModel}
                onSortModelChange={(sortModelParams) => {
                    if(!isEqual(sortModelParams,sortModel))
                    {
                        setSortModel(sortModelParams);
                    }
                }}
                filterMode={"server"}
                filterModel={filterModel}
                loading={loading}
                localeText={DataGridLocale}
                onCellClick={PasteToClipboard}
                components={{
                    Toolbar: CustomToolbar,
                }}
                onColumnWidthChange={(param: GridColumnResizeParams) => {
                    window.localStorage.setItem(`${columnWidthKeyPrefix}${param.colDef.field}`, param.width.toString())
                }}
                onColumnOrderChange={handleColumnOrderChange}
            />
            <div style={{display:"flex",border:"1px solid rgba(224, 224, 224, 1)",justifyContent:"flex-end",alignItems:"center"}}>
                <div style={{display:"flex"}}>
                    <small style={{display:"flex",alignItems:"center"}}>
                        顯示數量
                    </small>
                    <Select
                        style={{margin:"0 15px"}}
                        value={pageSize}
                        inputProps={{
                            id: "select-native",
                        }}

                        onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                            setPageSize(event.target.value as number);
                        }}
                    >
                        <MenuItem value={15}>
                            15
                        </MenuItem>
                        <MenuItem value={30}>
                            30
                        </MenuItem>
                        <MenuItem value={50}>
                            50
                        </MenuItem>
                        <MenuItem value={100}>
                            100
                        </MenuItem>
                        <MenuItem value={1000}>
                            1000
                        </MenuItem>
                    </Select>
                </div>

                {page != null && pageSize &&<div>
                    <small style={{alignItems: "center", display: "flex"}}>第
                    <TextField style={{width:"50px"}} type={"number"} value={page + 1}
                    inputProps={{ style: {textAlign: 'right'} }}
                           onChange={e=>
                           {
                               const val = parseInt(e.target.value);
                               setPage(page=>
                               {
                                   if(val > pageCount || val < 1 || isNaN(val) || val === Infinity)
                                   {
                                       return page;
                                   }
                                   else {
                                       return val - 1;
                                   }
                               });
                           }}/>頁 共有 {pageCount}頁</small>
                </div>}
                <div>
                    <Button onClick={()=>
                    {
                        setPage(page=>
                        {
                            if((page - 1) >= 0 )
                            {
                                return page - 1;
                            }
                            else
                            {
                                return page;
                            }
                        })
                    }}>{"<"}</Button>
                    <Button onClick={()=>setPage(page=>
                    {
                        if((page + 1) >= pageCount)
                        {
                            return page;
                        }
                        else
                        {
                            return page + 1;
                        }
                    })}>{">"}</Button>
                </div>

            </div>
        </div>
    );
};
