import { Box } from "@material-ui/core";
import React, {useState} from "react";
import { useGetProductListQuery } from "../../generated/graphql";
import DefaultQueryHandler from "../../common/DefaultQueryHandler";
import { dateValueFormatter,  PasteToClipboard, ViewColumn } from "../../common/Util";
import { DataGridLocale } from "../../common/DataGridLocale";
import {GridColDef, DataGridPro, GridSortModel} from "@mui/x-data-grid-pro";
import {ProductOriginList, ProductOriginMap} from "../../common/Constant";

export default () => {
    const { loading, data, error } = useGetProductListQuery();
    const [sortModel, setSortModel] = useState<GridSortModel>([{field: "id", sort: "desc"}]);

    const columns: GridColDef[] = [
        { field: "id", headerName: "id", width: 100, type: "number" },
        { field: "productOrigin", headerName: "原廠商", width: 100,
            type: "singleSelect",
            valueGetter: params => ProductOriginMap[params.row.productOrigin],
            valueOptions: params => ProductOriginList},
        {
            field: "name",
            headerName: "產品名稱",
            width: 150,
        },
        { field: "website", headerName: "網站", width: 200 },
        { field: "description", headerName: "描述", width: 200 },
        ViewColumn('/app/product'),
    ];
    // const onCellClick =
    return (
        <DefaultQueryHandler error={error} loading={loading}>
            <Box width={"100%"} style={{ marginTop: "30px"}}>
                <DataGridPro
                    sortModel={sortModel}
                    onSortModelChange={(model)=>setSortModel(model)}
                    columns={columns}
                    rows={data?.products!}
                    hideFooter={true}
                    autoHeight={true}
                    localeText={DataGridLocale}
                    onCellClick={PasteToClipboard}
                    disableColumnPinning={false}
                />
            </Box>
        </DefaultQueryHandler>
    );
};
